import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea bonus ideas";
export const description = "";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "bonus-ideas"
    }}>{`Bonus ideas`}</h1>
    <p>{`Here's a few bonus ideas to help you on your journey :)`}</p>
    <h2 {...{
      "id": "3-types-of-risk"
    }}>{`3 types of risk`}</h2>
    <Vimeo id="555736649" mdxType="Vimeo" />
    <br />
    <br />
    <p>{`If you're building for fun, go for it! Nothing else matters.`}</p>
    <p>{`If you want to monetize, there's 3 types of risk you have to think about:`}</p>
    <ol>
      <li parentName="ol">{`Execution risk`}</li>
      <li parentName="ol">{`Technology risk`}</li>
      <li parentName="ol">{`Market risk`}</li>
    </ol>
    <h2 {...{
      "id": "build-next-to-a-river-of-money"
    }}>{`Build next to a river of money`}</h2>
    <Vimeo id="555752460" mdxType="Vimeo" />
    <br />
    <br />
    <p>{`What you build matters less than who you're building it for.`}</p>
    <p>{`Selling ice cream on a busy LA beach in summer? Fantastic! Same ice cream at an Icelandic volcanoe in winter? Ehhh maybe not.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://justinjackson.ca/beach"
        }}>{`Selling stuff on a busy beach by Justin Jackson`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kaidavis.com/river-of-money/"
        }}>{`River of money by Kai Davis`}</a></li>
      <li parentName="ul">{`A book I enjoyed on market/audience validation is `}<a parentName="li" {...{
          "href": "http://momtestbook.com/"
        }}>{`The Mom Test`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      